export const mainSpace = ()=> {
    if (!document.body.classList.contains('page-template-landing-page')) {
        const func = ()=> {
            const header = document.querySelector('.header')
            const main = document.querySelector('main')
            if (header) {
                main.style = `padding-top: ${header.clientHeight}px;`;
            }
        }
        func()
        window.addEventListener('resize',()=> {
            func()
        })
    }
}