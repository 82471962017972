export const socialDropDown = ()=> {
    const wrapper = document.querySelector('.header__social-mobile')
    if (wrapper) {
        const head = wrapper.querySelector('.header__social-head')
        const list = wrapper.querySelector('.header__social-list')
        head?.addEventListener('click',()=> {
            wrapper.classList.toggle('active')
        })
    }

}