export const contactForms = ()=> {
    const contactForm = document.querySelectorAll('.contact__form-wrapper')

    contactForm?.forEach(item => {
        const thanksPop = item.querySelector('.contact__thanks');
        const formWrapper = item.querySelector('.contact__form');
        const form = formWrapper?.querySelector('form');
        form?.addEventListener('wpcf7mailsent', () => {
            thanksPop?.classList.add('show');
            formWrapper?.classList.add('hide');

            //scroll to top block

            const headerHeight = document.querySelector('.header').getBoundingClientRect().height
            const scrollBlock = form.closest('section')
            const elementOffset = (scrollBlock?.getBoundingClientRect().top + window.scrollY) - headerHeight
            // smoothScrollTo(elementOffset,2)
            window.scrollTo({
                top: elementOffset,
                behavior: "smooth",
            });
        })

        //disabled button when form submitting

        form?.addEventListener('submit', function(event) {
            const button = form.querySelector('input[type="submit"]');
            button.disabled = true;
            //button.value = "Sending...";
        });

        document.addEventListener('wpcf7submit', function(event) {
            var buttons = document.querySelectorAll('.wpcf7-submit[disabled]');
            buttons.forEach(function(button) {
                button.disabled = false;
                //button.value = "Send message";
            });
        });

    })
}