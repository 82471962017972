import {headerGeneral} from "./modules/header/header.js";
import {contactForms} from "./modules/contact-forms.js";

document.addEventListener("DOMContentLoaded", (event) => {
    headerGeneral();
    let scriptLaunchStart = true;
    const scriptLaunch = () => {
        contactForms();
    }
    if (window.pageYOffset >= 1 && scriptLaunchStart) {
        scriptLaunchStart = false;
        scriptLaunch();
    } else if (window.pageYOffset < 1 && scriptLaunchStart) {
        const events = ['mousemove', 'click', 'mousewheel', 'scroll', 'touchstart', 'keydown', 'keypress', 'mouseover', 'mouseout', 'resize'];
        events.forEach(event => {
            window.addEventListener(event, () => {
                if (scriptLaunchStart) {
                    scriptLaunchStart = false;
                    scriptLaunch();
                }
            });
        });
    }
});